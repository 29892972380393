import React, { useState } from 'react';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';
import './EditProfile.css'

const EditProfile = () => {
    const [profilePic, setProfilePic] = useState(null);
    const [fullName, setFullName] = useState('John Doe');
    const [username, setUsername] = useState('@johndoe');
    const [location, setLocation] = useState('New York, USA');
    const [aboutMe, setAboutMe] = useState('I am a software engineer with a passion for task management and productivity tools.');

    const handleProfilePicChange = (e) => {
        setProfilePic(URL.createObjectURL(e.target.files[0]));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Save changes logic
        console.log('Profile saved:', { fullName, username, location, aboutMe });
    };

    return (
        <>
        <AppNavTop />
        <section className="edit-profile container mt-3">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="card p-4 shadow-sm">
                        <h3 className="card-title text-center mb-4">Edit Profile</h3>
                        <form onSubmit={handleSubmit}>
                            {/* Profile Picture */}
                            <div className="text-center mb-4">
                                <img
                                    src={profilePic || "https://via.placeholder.com/100"}
                                    alt="User Avatar"
                                    className="rounded-circle mb-3"
                                    width="100"
                                    height="100"
                                />
                                <div className="mb-3">
                                    <label htmlFor="profilePic" className="form-label">Update Profile Picture</label>
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="profilePic"
                                        onChange={handleProfilePicChange}
                                    />
                                </div>
                            </div>

                            {/* Full Name */}
                            <div className="mb-3">
                                <label htmlFor="fullName" className="form-label">Full Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fullName"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                            </div>

                            {/* Username */}
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Username</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>

                            {/* Location */}
                            <div className="mb-3">
                                <label htmlFor="location" className="form-label">Location</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="location"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </div>

                            {/* About Me */}
                            <div className="mb-3">
                                <label htmlFor="aboutMe" className="form-label">About Me</label>
                                <textarea
                                    className="form-control"
                                    id="aboutMe"
                                    rows="4"
                                    value={aboutMe}
                                    onChange={(e) => setAboutMe(e.target.value)}
                                />
                            </div>

                            {/* Save Button */}
                            <div className="d-grid gap-2">
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <AppNavBottom />
        </>
    );
};

export default EditProfile;
