import React from 'react';
import { Link } from 'react-router-dom';
import { FaSignOutAlt, FaSignInAlt, FaUserPlus, FaUser, FaUserEdit, FaCog, FaUserCircle, FaBell, FaFlagCheckered, FaSearch, FaFilter, FaUserFriends } from 'react-icons/fa';
import './AppNavTop.css'
import AppNavMain from '../AppNavMain/AppNavMain'

const AppNavTop = () => (
    <>
    <nav className="navbar navbar-light bg-light">
            <div className="container-fluid nav-container">
                <AppNavMain />
                <Link to="/tasks" className="navbar-brand">PopVia</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarApp"
                    aria-controls="navbarApp" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><FaUserCircle /></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarApp">
                    <ul className="navbar-nav ms-auto">
                        {/*<li className="nav-item">
                            <Link className="nav-link" to="/notifications"><FaBell /> Notifications <span class="badge text-bg-secondary">3</span></Link>
                        </li>*/}
                        {/*<li className="nav-item">
                            <Link className="nav-link" to="/goals"><FaFlagCheckered /> Goals</Link>
                        </li>*/}
                        {/*<li className="nav-item">
                            <Link className="nav-link" to="/coaches"><FaSearch /> Find a Coach</Link>
                        </li>*/}
                        {/*<li className="nav-item">
                            <Link className="nav-link" to="/coach-profile"><FaUser /> Coach Profile</Link>
                        </li>*/}
                        {/*<li className="nav-item">
                            <Link className="nav-link" to="/buddy-search"><FaUserFriends /> Buddies</Link>
                        </li>*/}
                        <li className="nav-item">
                            <Link className="nav-link" to="/profile"><FaUser /> Profile</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/edit-profile"><FaUserEdit /> Edit Profile</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/settings"><FaCog /> Settings</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/login"><FaSignInAlt /> Login</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/register"><FaUserPlus /> Register</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/logout"><FaSignOutAlt /> Logout</Link>
                        </li>
                    </ul>
                </div>
            </div>
            {/*
            <div className="container-fluid mt-1 search-container">
                <div className="search-item"><FaSearch /></div>
                <div className="search-item"><input class="form-control form-control-dark mb-0" type="text" placeholder="Search" aria-label="Search"></input></div>
                <div className="search-item"><FaFilter /></div>
            </div>*/}
        </nav>
    </>
)

export default AppNavTop;
