import React from 'react';
import NavBar from '../../../components/website/global/NavBar/NavBar';
import Resources from '../../../components/website/Resources/Resources'
import CallToAction from '../../../components/website/CallToAction/CallToAction';
import Footer from '../../../components/website/global/Footer/Footer';

function ResourcesPage() {
    return (
        <>
            <NavBar />

            <Resources />

            <CallToAction />

            <Footer />
        </>
    );
}

export default ResourcesPage;
