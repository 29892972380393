import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Badge } from 'react-bootstrap';

const TaskModal = ({ show, handleClose, handleSubmit, task, mode }) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        project: '',
        dueDate: '',
        dueTime: '',
        reminder: 'Once',
        duration: '',
        tags: []
    });
    const [newTag, setNewTag] = useState('');

    useEffect(() => {
        if (mode === 'edit' && task) {
            setFormData({
                title: task.title || '',
                description: task.description || '',
                project: task.project || '',
                dueDate: task.dueDate || '',
                dueTime: task.dueTime || '',
                reminder: task.reminder || 'Once',
                duration: task.duration || '',
                tags: task.tags || []
            });
        } else {
            setFormData({
                title: '',
                description: '',
                project: '',
                dueDate: '',
                dueTime: '',
                reminder: 'Once',
                duration: '',
                tags: []
            });
        }
    }, [mode, task, show]);

    const onChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    };

    const addTag = () => {
        if (newTag.trim() && !formData.tags.includes(newTag.trim())) {
            setFormData(prev => ({
                ...prev,
                tags: [...prev.tags, newTag.trim()]
            }));
            setNewTag('');
        }
    };

    const removeTag = (tagToRemove) => {
        setFormData(prev => ({
            ...prev,
            tags: prev.tags.filter(tag => tag !== tagToRemove)
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit(formData);
        handleClose();
    };

    // Helper to convert "mm-dd-yyyy" to "yyyy-mm-dd" for editing
    const convertToInputFormat = (dateStr) => {
        const [month, day, year] = dateStr.split('-');
        return `${year}-${month}-${day}`;
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{mode === 'edit' ? 'Edit Task' : 'Add New Task'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    {/* Other form fields */}
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Label>Task Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter task title"
                            value={formData.title}
                            onChange={onChange}
                            required
                        />
                    </Form.Group>

                    {/* Add Tags Section */}
                    <Form.Group className="mb-3">
                        <Form.Label>Tags</Form.Label>
                        <div className="d-flex mb-2">
                            <Form.Control
                                type="text"
                                placeholder="Add a tag"
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                            />
                            <Button variant="outline-secondary" onClick={addTag} className="ms-2">Add</Button>
                        </div>
                        <div>
                            {formData.tags.map((tag, index) => (
                                <Badge key={index} bg="primary" className="me-1">
                                    {tag} <span style={{ cursor: 'pointer' }} onClick={() => removeTag(tag)}>×</span>
                                </Badge>
                            ))}
                        </div>
                    </Form.Group>

                    {/* Other fields such as due date, time, etc. */}

                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Task Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter task description"
                            value={formData.description}
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="project">
                        <Form.Label>Assign to Project</Form.Label>
                        <Form.Control
                            as="select"
                            value={formData.project}
                            onChange={onChange}
                        >
                            <option value="">Select a project</option>
                            {/* Replace with dynamic project options if available */}
                            <option value="Project 1">Project 1</option>
                            <option value="Project 2">Project 2</option>
                            <option value="Project 3">Project 3</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="dueDate">
                        <Form.Label>Due Date</Form.Label>
                        {/* Bug Fix Needed */}
                        <Form.Control
                            type="date"
                            value={formData.dueDate || ''}
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="dueTime">
                        <Form.Label>Due Time</Form.Label>
                        <Form.Control
                            type="time"
                            value={formData.dueTime || ''}
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="reminder">
                        <Form.Label>Reminder</Form.Label>
                        <Form.Control
                            as="select"
                            value={formData.reminder}
                            onChange={onChange}
                        >
                            <option>Once</option>
                            <option>Every Day</option>
                            <option>Every Week</option>
                            <option>Every Month</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="duration">
                        <Form.Label>Duration</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="e.g., 45 min"
                            value={formData.duration}
                            onChange={onChange}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        {mode === 'edit' ? 'Save Changes' : 'Create Task'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default TaskModal;
