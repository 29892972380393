import React, { useState } from 'react';
import './Inbox.css'; // Import the external CSS file
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';
import Mailbox from './MailBox/Mailbox';

const Inbox = () => {
   // Sample data for emails
   const [emails, setEmails] = useState([
       { id: 1, subject: "Welcome to PopVia!", sender: "team@popvia.com", content: "Thanks for joining PopVia! We’re excited to have you on board.", read: false },
       { id: 2, subject: "Weekly Tips", sender: "support@popvia.com", content: "Here are some productivity tips to help you make the most of our app.", read: false },
       { id: 3, subject: "Product Update", sender: "updates@popvia.com", content: "We’ve made some improvements to the PopVia experience.", read: true },
        { id: 1, subject: "Welcome to PopVia!", sender: "team@popvia.com", content: "Thanks for joining PopVia! We’re excited to have you on board.", read: false },
       { id: 2, subject: "Weekly Tips", sender: "support@popvia.com", content: "Here are some productivity tips to help you make the most of our app.", read: false },
       { id: 3, subject: "Product Update", sender: "updates@popvia.com", content: "We’ve made some improvements to the PopVia experience.", read: true },
   ]);
   const [selectedEmail, setSelectedEmail] = useState(null);

   // Handle email selection
   const openEmail = (id) => {
       setSelectedEmail(id);
       // Mark email as read
       setEmails(emails.map(email => email.id === id ? { ...email, read: true } : email));
   };

   // Handle closing the email view
   const closeEmail = () => setSelectedEmail(null);

   return (
    <>
        <AppNavTop />
        <div className="container-fluid mt-2 margin-bottom-200">
            <div className="row">
                <Mailbox />
            </div>{/* End Row */}
        </div>{/* End Container */}
        <AppNavBottom />
    </>
   );
};

export default Inbox;
