import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserFriends, FaStopwatch20, FaGripVertical, FaCalendar, FaClock, FaRibbon, FaBookmark, FaList, FaBriefcase, FaBell } from 'react-icons/fa';
import './AppNavBottom.css'

const AppNavBottom = () => (
<>
    {/* Bottom Navigation */}
        <footer className="bottom-nav">
            <div className="footer-nav-container">
                <div className="nav-item"><Link to="/projects"><FaBriefcase /> Projects</Link></div>
                <div className="nav-item"><Link to="/tasks"><FaList /> Tasks</Link></div>
                {/*<Link to="/notifications"><FaBell /> Notifications</Link>*/}
            </div>
        </footer>
</>
)

export default AppNavBottom;
