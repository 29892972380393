import React from 'react';
import './CoachProfile.css';
import AppNavTop from '../../AppNavTop/AppNavTop';
import AppNavBottom from '../../AppNavBottom/AppNavBottom'

const CoachProfile = () => {
    const handleSubmit = (e)=> {
        e.preventDefault();
        console.log('You clicked submit.');
    }

  return (
    <>
    <AppNavTop />

        <div className="modal fade" id="contactModal" tabIndex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="contactModalLabel">Contact</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="contactSubject" className="form-label">Subject</label>
                                <input type="text" className="form-control" id="contactSubject" placeholder="Enter task title" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="contactBody" className="form-label">Message Body</label>
                                <textarea className="form-control" id="contactBody" rows="3"></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary" preventDefault="true">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid coach-profile-container">
            <div className="coach-profile mt-3">
            <div className="profile-header">
                <div className="avatar">
                <span className="role">ADHD Coach</span>
                </div>
                <h2 className="coach-name">Babette Dell</h2>
                <p className="coach-timezone">⏰ EST</p>
                <p className="coach-education">🎓 Harvard University</p>
            </div>
            <div className="rating">
                <span className="star">☆</span>
                <span className="star">☆</span>
                <span className="star">☆</span>
                <span className="star">☆</span>
                <span className="star">☆</span>
            </div>
            <div className="contact-coach mb-3">
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#contactModal">Contact Coach</button>
            </div>
            <div className="bio">
                <p className="bio-text">Babette Dell is a Harvard trained psychologist and certified ADHD coach.</p>
            </div>
            <div className="reviews">
                <h3>Reviews</h3>
                <div className="review">
                <p className="review-text">I am leaving this review</p>
                <div className="review-author">
                    <span className="review-author-name">Ivan Island</span>
                    <span className="review-date">Nov 6, 2024</span>
                </div>
                </div>
            </div>
            </div>
        </div>
    <AppNavBottom />
    </>
  );
};

export default CoachProfile;
