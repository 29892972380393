import React from 'react';
import NavBar from '../../../components/website/global/NavBar/NavBar'
import AboutUs from '../../../components/website/AboutUs/AboutUs'
import OurMission from '../../../components/website/OurMission/OurMission'
import CoreValues from '../../../components/website/CoreValues/CoreValues'
import OurTeam from '../../../components/website/OurTeam/OurTeam'
import Footer from '../../../components/website/global/Footer/Footer'

function AboutPage() {
    return (
        <>
            <NavBar />

            <AboutUs />

            <OurMission />

            <OurTeam />

            <Footer />
        </>
    );
}

export default AboutPage;
