import React, { useState, useEffect } from 'react';
import './WorkTimer.css';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';

const WorkTimer = () => {
  const [taskName, setTaskName] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [taskHistory, setTaskHistory] = useState([]);

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRunning]);

  const handleStart = () => {
    if (taskName.trim() !== '') {
      setIsRunning(true);
    }
  };

  const handleStop = () => {
    setIsRunning(false);
    if (taskName.trim() !== '' && elapsedTime > 0) {
      const newTask = {
        name: taskName,
        time: elapsedTime,
      };
      setTaskHistory([...taskHistory, newTask]);
      setTaskName('');
      setElapsedTime(0);
    }
  };

  const handleReset = () => {
    setIsRunning(false);
    setElapsedTime(0);
    setTaskName('');
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <>
    <AppNavTop />
    <div className="work-timer mt-3 margin-bottom-200">
      <h2>Work Timer</h2>
      <div className="timer-controls">
        <input
          type="text"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          placeholder="Enter task name"
          disabled={isRunning}
        />
        <div className="buttons">
          <button onClick={handleStart} disabled={isRunning || taskName.trim() === ''}>
            Start
          </button>
          <button onClick={handleStop} disabled={!isRunning}>
            Stop
          </button>
          <button onClick={handleReset} disabled={isRunning || elapsedTime === 0}>
            Reset
          </button>
        </div>
        <div className="timer-display">
          <p>Elapsed Time: {formatTime(elapsedTime)}</p>
        </div>
      </div>

      <h3>Task Summary</h3>
      <div className="task-summary">
        {taskHistory.length === 0 ? (
          <p>No tasks completed yet.</p>
        ) : (
          <ul>
            {taskHistory.map((task, index) => (
              <li key={index}>
                <strong>{task.name}</strong> - {formatTime(task.time)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
    <AppNavBottom />
    </>
  );
};

export default WorkTimer;
