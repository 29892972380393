import React, { useState } from 'react';
import './Notes.css'; // Import the external CSS file
import AppNavTop from '../AppNavTop/AppNavTop'
import AppNavBottom from '../AppNavBottom/AppNavBottom'

const Notes = () => {
   const [notes, setNotes] = useState([]);
   const [noteText, setNoteText] = useState("");
   const [expandedNoteIndex, setExpandedNoteIndex] = useState(null);

   // Add a new note to the list
   const addNote = () => {
       if (noteText.trim()) {
           setNotes([...notes, noteText]);
           setNoteText("");
       }
   };

   // Toggle expanding or collapsing the full note
   const toggleExpandNote = (index) => {
       setExpandedNoteIndex(expandedNoteIndex === index ? null : index);
   };

   return (
    <>
    <AppNavTop />
       <div className="notes-container mt-3 margin-bottom-200">
           <h2>Notes</h2>
           <textarea
               className="notes-textarea"
               placeholder="Write your note here..."
               value={noteText}
               onChange={(e) => setNoteText(e.target.value)}
           />
           <button className="add-note-button" onClick={addNote}>Add Note</button>

           <div className="notes-list">
               {notes.map((note, index) => (
                   <div
                       key={index}
                       className="note-preview"
                       onClick={() => toggleExpandNote(index)}
                   >
                       {expandedNoteIndex === index
                           ? <p>{note}</p>
                           : <p>{note.length > 100 ? `${note.substring(0, 100)}...` : note}</p>}
                   </div>
               ))}
           </div>
       </div>
       <AppNavBottom />
       </>
   );
};

export default Notes;
