import React from 'react';

function Footer() {
    return (
        <footer className="container-fluid bg-dark text-white py-4 text-center">
            <p>&copy; 2024 PopVia. All Rights Reserved.</p>
        </footer>
    );
}

export default Footer;
