import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './FeedbackForm.css';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';

const FeedbackForm = () => {
    const [subject, setSubject] = useState('');
    const [feedback, setFeedback] = useState('');
    const [emotion, setEmotion] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = { subject, feedback, emotion };

        try {
            const response = await submitFeedback(formData);
            if (response.ok) {
                alert("Feedback submitted successfully!");
                navigate("/tasks");
            } else {
                console.error("Failed to submit feedback:", response.statusText);
                alert("Failed to submit feedback. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting feedback:", error);
            alert("An error occurred. Please try again.");
        }
    };

    const submitFeedback = async (data) => {
        return fetch("https://your-api-url.com/feedback", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    };

    return (
        <>
        <AppNavTop />
        <section className="feedback-form container mt-3 margin-bottom-200">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <h2 className="text-center mb-4">We Value Your Feedback</h2>
                    <div className="card p-4 shadow-sm shadow rounded-border">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="subject" className="form-label">Subject</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="subject"
                                    placeholder="Enter feedback subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="feedbackBody" className="form-label">Feedback</label>
                                <textarea
                                    className="form-control"
                                    id="feedbackBody"
                                    rows="4"
                                    placeholder="Enter your feedback"
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="emotion" className="form-label">Emotion</label>
                                <select
                                    className="form-select"
                                    id="emotion"
                                    value={emotion}
                                    onChange={(e) => setEmotion(e.target.value)}
                                >
                                    <option value="">Select an emotion</option>
                                    <option value="Angry">😠 Angry</option>
                                    <option value="Sad">😥 Sad</option>
                                    <option value="Happy">🙂 Happy</option>
                                    <option value="Inspired">🤩 Inspired</option>
                                </select>
                            </div>
                            <div className="d-grid gap-2">
                                <button type="submit" className="btn btn-primary">Submit Feedback</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <AppNavBottom />
        </>
    );
};

export default FeedbackForm;
