import React from "react";
import "./Coaches.css";
import AppNavTop from "../AppNavTop/AppNavTop";
import AppNavBottom from '../AppNavBottom/AppNavBottom';

// Individual Coach Card Component
const CoachCard = ({ coach }) => {
  const { profileImage, fullName, rating } = coach;

  // Helper function to render stars
  const renderStars = (rating) => {
    return Array(5)
      .fill(0)
      .map((_, index) => (
        <span key={index} className={index < rating ? "star filled" : "star"}>★</span>
      ));
  };

  return (
    <div className="coach">
      <img src={profileImage} alt={`${fullName}'s profile`} className="coach-image" />
      <h3 className="coach-name">{fullName}</h3>
      <div className="coach-rating">{renderStars(rating)}</div>
    </div>
  );
};

// Main Coach List Component
const Coaches = () => {
const coaches = [
  { id: 1, profileImage: "image1.jpg", fullName: "Jane Doe", rating: 5 },
  { id: 2, profileImage: "image2.jpg", fullName: "John Smith", rating: 4 },
  { id: 3, profileImage: "image3.jpg", fullName: "Alice Johnson", rating: 3 },
  { id: 1, profileImage: "image1.jpg", fullName: "Jane Doe", rating: 5 },
  { id: 2, profileImage: "image2.jpg", fullName: "John Smith", rating: 4 },
  { id: 3, profileImage: "image3.jpg", fullName: "Alice Johnson", rating: 3 },
];

  return (
    <>
    <AppNavTop />
    <div className="coach-list mt-3">
      {coaches.map((coach) => (
        <CoachCard key={coach.id} coach={coach} />
      ))}
    </div>
    <AppNavBottom />
    </>
  );
};

export default Coaches;
