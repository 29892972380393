import React from 'react';
import './ComingSoon.css'

const ComingSoon = () => {

  return (
    <div className='coming-soon-container mt-5'>
      <h1 className='coming-soon-heading'>Coming Soon</h1>
      <p className="coming-soon-text">We're working hard to bring you something amazing. Stay tuned!</p>
    </div>
  );
};

export default ComingSoon;
