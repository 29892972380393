import React from 'react';
import { Link } from "react-router-dom";

const LoginForm = () => {
    return (
        <section className="container mt-3">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <h2 className="text-center mb-4">Login to Your Account</h2>
                    <div className="card p-4 shadow-sm">
                        <form>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="email" placeholder="Enter your email" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input type="password" className="form-control" id="password" placeholder="Enter your password" />
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="rememberMe" />
                                <label className="form-check-label" htmlFor="rememberMe">Remember Me</label>
                            </div>
                            <div className="d-grid gap-2">
                                <Link to="/register" className="btn btn-primary">Login</Link>
                            </div>
                            <p className="mt-3 text-center">Don't have an account? <Link className="mt-3 text-center" to="/register">Sign Up</Link></p>
                            <p className="mt-2 text-center"><Link className="mt-3 text-center" to="/forgot-password">Forgot your password?</Link></p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoginForm;
