import React from 'react';
import NavBar from '../../../components/website/global/NavBar/NavBar';
import Footer from '../../../components/website/global/Footer/Footer';
import { Link } from 'react-router-dom';

const SalesPage = () => {
    return (
        <div>
            <NavBar />
            <HeroSection />
            <WhyPreSales />
            <Features />
            <CallToAction />
            <Footer />
        </div>
    );
};

const HeroSection = () => (
    <section className="container-fluid bg-primary text-white text-center py-5">
        <h1 className="text-white">Get Early Access to PopVia</h1>
        <p className="lead">
            Join our pre-sales list and be one of the first to experience an ADHD-friendly task management app
            designed to help you stay focused and productive.
        </p>
        <a href="#pre-sales-form" className="btn btn-light btn-lg">
            Secure a Lifetime Membership
        </a>
    </section>
);

const WhyPreSales = () => (
    <section className="container mt-3">
        <div className="row">
            <div className="col-lg-12 text-center mb-4">
                <h2>Why Join the Pre-Sales?</h2>
                <p className="text-muted">Be the first to enjoy exclusive features and benefits as an early adopter.</p>
            </div>
            <BenefitCard title="Exclusive Early Access" text="Gain access to the app before the public launch and get a head start on organizing your tasks with ADHD-friendly features." />
            <BenefitCard title="Lifetime Discounts" text="Receive special pricing and discounts as part of our early adopter program, available for the lifetime of your account." />
            <BenefitCard title="Help Shape the App" text="As an early adopter, your feedback will help shape the future of the app, ensuring it meets your unique needs and preferences." />
        </div>
    </section>
);

const BenefitCard = ({ title, text }) => (
    <div className="col-lg-4 mb-4">
        <div className="card h-100 text-center">
            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{text}</p>
            </div>
        </div>
    </div>
);

const Features = () => (
    <section className="container mt-3">
        <div className="row">
            <div className="col-lg-12 text-center mb-4">
                <h2>App Features</h2>
                <p className="text-muted">
                    Discover the PopVia features designed to keep you on track and motivated.
                </p>
            </div>
            <FeatureCard title="Task Management" text="Easily create, organize, and track your tasks with a simple and intuitive interface tailored to ADHD needs." />
            <FeatureCard title="Custom Reminders" text="Set custom reminders to stay on top of deadlines and tasks, helping you stay focused throughout the day." />
            <FeatureCard title="Buddy System" text="Connect with accountability buddies, track each other’s progress, and provide encouragement along the way." />
        </div>
    </section>
);

const FeatureCard = ({ title, text }) => (
    <div className="col-lg-4 mb-4">
        <div className="card h-100 text-center">
            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{text}</p>
            </div>
        </div>
    </div>
);

const CallToAction = () => (
    <section className="container-fluid bg-light py-5 text-center" id="pre-sales-form">
        <h3>Secure a Lifetime Membership Now!</h3>
        <p className="lead">
            Don’t miss out on this opportunity to get early access to PopVia.
        </p>
        <Link to="https://buy.stripe.com/28og25ahT5lo9y04gh" className="btn btn-primary btn-lg">Buy Now</Link>
    </section>
);

{/*const PreSalesForm = () => (
    <form className="container mt-4">
        <div className="row justify-content-center">
            <div className="col-lg-6">
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Full Name</label>
                    <input type="text" className="form-control" id="name" placeholder="Enter your name" />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email Address</label>
                    <input type="email" className="form-control" id="email" placeholder="Enter your email" />
                </div>
                <button type="submit" className="btn btn-primary btn-lg w-100">Join Pre-Sales</button>
            </div>
        </div>
    </form>
);*/}

export default SalesPage;
