import React, { useState } from 'react';
import './BrowserHistory.css';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';
import {Link} from 'react-router-dom';

const BrowserHistory = () => {
  const [historyData, setHistoryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Handle file upload and parse JSON
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = JSON.parse(e.target.result);
          setHistoryData(data);
        } catch (error) {
          alert('Failed to parse file. Please upload a valid JSON file.');
        }
      };
      reader.readAsText(file);
    }
  };

  // Filter history entries based on search query
  const filteredHistory = historyData.filter((entry) =>
    entry.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.url.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
    <AppNavTop />
    <div className="browser-history">
      <h2>Browser History</h2>
      <h4>Install this Chrome Extension</h4>
      <Link to="https://chromewebstore.google.com/detail/export-chrome-history/dihloblpkeiddiaojbagoecedbfpifdj" target="_blank">Export Chrome History</Link>
      <p>Download the browser history as JSON. Then, upload below.</p>
      <input type="file" onChange={handleFileUpload} accept=".json" className="file-input" />
      <input
        type="text"
        placeholder="Search history..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
      />

      <ul className="history-list">
        {filteredHistory.length > 0 ? (
          filteredHistory.map((entry, index) => (
            <li key={index} className="history-item">
              <h3 className="history-title">{entry.title}</h3>
              <a href={entry.url} target="_blank" rel="noopener noreferrer" className="history-url">
                {entry.url}
              </a>
              <p className="history-visit-time">Visited: {new Date(entry.visitTime).toLocaleString()}</p>
            </li>
          ))
        ) : (
          <p className="no-history">No history found</p>
        )}
      </ul>
    </div>
    <AppNavBottom />
    </>
  );
};

export default BrowserHistory;
