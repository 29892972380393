import React from 'react';
import { FaBars } from 'react-icons/fa';
import { Outlet, Link } from "react-router-dom";

function NavBar() {
    return (
        <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">PopVia</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><FaBars /></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/resources">Resources</Link>
                        </li>
                        {/*<li className="nav-item">
                            <Link className="nav-link" target="_blank" to="https://popvia.canny.io/feature-requests">Feature Requests</Link>
                        </li>*/}
                        <li className="nav-item">
                            <Link className="nav-link" to="/discord">Discord Community</Link>
                        </li>
                        {/*<li className="nav-item">
                            <Link className="nav-link" to="/lifetime">Lifetime Membership</Link>
                        </li>*/}
                    </ul>
                </div>
            </div>
        </nav>
        <Outlet />
        </>
    );
}

export default NavBar;
