import React from 'react';
import {Link} from 'react-router-dom';
import NavBar from '../../../components/website/global/NavBar/NavBar'
import Footer from '../../../components/website/global/Footer/Footer'

const CommunityPage = () => {
    return (
        <>
        <NavBar />
        <div>
            {/* Hero Section */}
            <section className="container-fluid bg-primary text-white text-center py-5">
                <h1 className="text-white">Join Our Discord Community</h1>
                <p className="lead">
                    Connect with others, share tips, and stay motivated as part of our ADHD Task Manager Discord Community!
                </p>
                <Link className="btn btn-light btn-lg" target="_blank" to="https://discord.gg/cwaNMeJYua">Join Our Discord</Link>
            </section>

            {/* Why Join Section */}
            <section className="container mt-3">
                <div className="row">
                    <div className="col-lg-12 text-center mb-4">
                        <h2>Why Join Our Discord Community?</h2>
                        <p className="text-muted">
                            A supportive and motivating community for individuals with ADHD to connect, share, and grow together.
                        </p>
                    </div>

                    {/* Reason 1: Find Support */}
                    <div className="col-lg-4 mb-4">
                        <div className="card h-100 text-center">
                            <div className="card-body">
                                <h5 className="card-title">Find Support</h5>
                                <p className="card-text">
                                    Connect with others who understand the unique challenges of ADHD and find the support you need to stay on track.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Reason 2: Share Tips */}
                    <div className="col-lg-4 mb-4">
                        <div className="card h-100 text-center">
                            <div className="card-body">
                                <h5 className="card-title">Share Tips & Tricks</h5>
                                <p className="card-text">
                                    Exchange productivity tips, task management strategies, and tools that have worked for others in the community.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Reason 3: Stay Motivated */}
                    <div className="col-lg-4 mb-4">
                        <div className="card h-100 text-center">
                            <div className="card-body">
                                <h5 className="card-title">Stay Motivated</h5>
                                <p className="card-text">
                                    Receive encouragement, celebrate your wins, and stay motivated by participating in our community challenges and discussions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Call to Action Section */}
            <section className="container-fluid bg-light py-5 text-center">
                <h3>Join Our Growing Discord Community Today!</h3>
                <p className="lead">
                    Don't miss out on the opportunity to connect with others and enhance your productivity. Click the button below to join.
                </p>
                <Link className="btn btn-primary btn-lg" target="_blank" to="https://discord.gg/cwaNMeJYua">Join Our Discord</Link>
            </section>
        </div>
        <Footer />
        </>
    );
};

export default CommunityPage;
