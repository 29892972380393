import React from 'react';
import './PublicProfile.css';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';

const PublicProfile = () => {
    return (
        <>
        <AppNavTop />
        <section className="public-profile container mt-3">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <div className="card p-4 shadow-sm">
                        <div className="text-center mb-4">
                            <img
                                src="https://via.placeholder.com/100"
                                alt="User Avatar"
                                className="rounded-circle mb-3"
                            />
                            <h3 className="card-title">John Doe</h3>
                            <p className="text-muted">@johndoe</p>
                            <p><strong>Location:</strong> New York, USA</p>
                        </div>

                        <div className="profile-details">
                            <h5>About Me</h5>
                            <p>
                                I am a software engineer with a passion for task management and productivity tools. I
                                specialize in building apps to help ADHDers manage their tasks and stay on track.
                            </p>

                            <h5>Recent Achievements</h5>
                            <ul className="list-group list-group-flush mb-4">
                                <li className="list-group-item">Completed 50 tasks this month</li>
                                <li className="list-group-item">Helped a buddy complete their goals</li>
                                <li className="list-group-item">
                                    Maintained a streak of 15 consecutive days of task completion
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <AppNavBottom/>
        </>
    );
};

export default PublicProfile;
