import React, { useState, useEffect } from 'react';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';
import { FaBookmark, FaBriefcase, FaCalendar, FaClock, FaStopwatch, FaSort, FaPlus } from 'react-icons/fa';
import './Tasks.css';
import TaskModal from './TaskModal';

const Tasks = () => {
    const [tasks, setTasks] = useState([
        { id: 1, title: "Long example title for this task", description: "Complete the mockup design", dueDate: "2024-10-20", dueTime: "10:00", reminder: "Every Day", project: "Super annoyingly long project title", duration: "45 min", tags: ["test", "next"] },
        { id: 2, title: "Example Task 2", description: "Review project proposal", dueDate: "2024-10-20", dueTime: "11:00", reminder: "Every Week", project: "Project 2"  },
        { id: 3, title: "Example Task 3", description: "Prepare meeting agenda", dueDate: "2024-10-20", dueTime: "13:00", reminder: "Custom", project: "Project 1"  },
        { id: 4, title: "Example Task 4", description: "Do another thing", dueDate: "2024-10-20", dueTime: "8:00", reminder: "Every Day", project: "Project 3"  },
        { id: 5, title: "Example Task 2", description: "Review project proposal", dueDate: "2024-10-20", dueTime: "11:00", reminder: "Every Week", project: "Project 2"  },
        { id: 6, title: "Example Task 3", description: "Prepare meeting agenda", dueDate: "2024-10-20", dueTime: "13:00", reminder: "Custom", project: "Project 1"  },
        { id: 7, title: "Example Task 4", description: "Do another thing", dueDate: "2024-10-20", dueTime: "8:00", reminder: "Every Day", project: "Project 3"  },
        { id: 8, title: "Example Task 4", description: "Do another thing", dueDate: "2024-10-21", dueTime: "7:15", reminder: "Every Day", project: "Project 3"  },
        { id: 9, title: "Example Task 4", description: "Do another thing", dueDate: "2024-10-18", dueTime: "14:00", reminder: "Every Day", project: "Project 3"  },
    ]);
    const [showModal, setShowModal] = useState(false);
    const [currentTask, setCurrentTask] = useState(null);
    const [mode, setMode] = useState('add');
    const [sortOrder, setSortOrder] = useState('asc'); // Default sort order is ascending

    // Automatically sort tasks whenever tasks list or sort order changes
    useEffect(() => {
        sortTasks(sortOrder);
    }, [tasks, sortOrder]);

    // Show the modal for creating or editing a task
    const handleShowModal = (task = null) => {
        setCurrentTask(task ? { ...task } : null);
        setMode(task ? 'edit' : 'add'); // If task is null, we're adding a new task
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    // Add or edit task and then close the modal
    const handleSubmitModal = (formData) => {
        if (mode === 'add') {
            const newTask = { id: tasks.length + 1, ...formData };
            setTasks([...tasks, newTask]);
        } else if (mode === 'edit' && currentTask) {
            setTasks(tasks.map(task => task.id === currentTask.id ? { ...task, ...formData } : task));
        }
        handleCloseModal();
    };

    // Helper to format due time from 24-hour to 12-hour format with am/pm
    const formatDateTimeLocal = (timeStr) => {
        let [hours, minutes] = timeStr.split(':');
        hours = parseInt(hours, 10);
        const isPM = hours >= 12;
        hours = hours % 12 || 12;
        const modifier = isPM ? 'pm' : 'am';
        return `${hours}:${minutes} ${modifier}`;
    };

    // Helper to convert "yyyy-mm-dd" to "mm-dd-yyyy" for display
    const convertToMMDDYYYY = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        return `${month}-${day}-${year}`;
    };

    // Function to display date and/or time conditionally
    const renderDueDateTime = (dueDate, dueTime) => {
        if (!dueDate && dueTime) {
            const today = new Date();
            dueDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
        }

        if (dueDate && dueTime) {
            return (
                <span className="badge rounded-pill text-bg-secondary">
                    <FaCalendar /> {convertToMMDDYYYY(dueDate)} | <FaClock /> {formatDateTimeLocal(dueTime)}
                </span>
            );
        } else if (dueDate) {
            return (
                <span className="badge rounded-pill text-bg-secondary">
                    <FaCalendar /> {convertToMMDDYYYY(dueDate)}
                </span>
            );
        } else if (dueTime) {
            return (
                <span className="badge rounded-pill text-bg-secondary">
                    <FaClock /> {formatDateTimeLocal(dueTime)}
                </span>
            );
        }
        return ''; // If neither dueDate nor dueTime, return an empty string
    };

    // Function to sort tasks by date first, then by time, with a toggle for ascending/descending order
    const sortTasks = (order) => {
        const sortedTasks = [...tasks].sort((a, b) => {
            // First compare by date
            const dateComparison = new Date(a.dueDate) - new Date(b.dueDate);
            if (dateComparison === 0) {
                // If dates are the same, compare by time
                const timeComparison = new Date(`${a.dueDate}T${a.dueTime}`) - new Date(`${b.dueDate}T${b.dueTime}`);
                return order === 'asc' ? timeComparison : -timeComparison;
            }
            return order === 'asc' ? dateComparison : -dateComparison;
        });
        setTasks(sortedTasks);
    };

    // Toggle sort order between ascending and descending
    const toggleSortOrder = () => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
    };

    return (
        <>
        <AppNavTop />
        <div className="margin-bottom-200">
            <section className="container mt-3">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 col-sm-12">
                        <div>
                            <h3 className="text-center">My Tasks</h3>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div>
                                <button className="btn btn-primary me-2" onClick={() => handleShowModal()}>
                                    <FaPlus /> Add Task
                                </button>
                                <button className="btn btn-light" onClick={toggleSortOrder}>
                                    <FaSort /> Sort by Due Date & Time ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
                                </button>
                            </div>
                        </div>

                        <div id="task-list" className="mt-4">
                            {tasks.map(task => (
                                <div key={task.id} className="task card mb-3 border p-3 shadow black-border rounded-border">
                                    <div className="row">
                                        <a href="javascript:void(0);" className="text-decoration-none cursor-pointer" data-bs-toggle="collapse" data-bs-target={`#taskDetails${task.id}`}>
                                            {task.project && (
                                                <div>
                                                    <p className="text-muted mb-0"><FaBriefcase /> {task.project.length > 30 ? `${task.project.substring(0, 30)}...` : task.project}</p>
                                                </div>
                                            )}
                                            {task.title && (
                                                <div>
                                                    <h5 className="text-muted">{task.title.length > 65 ? `${task.title.substring(0, 65)}...` : task.title}</h5>
                                                </div>
                                            )}
                                        </a>
                                        <div>
                                            {renderDueDateTime(task.dueDate, task.dueTime)}
                                            {task.tags && task.tags.map(tag => (
                                                <span key={tag} className="badge rounded-pill text-bg-secondary">{tag}</span>
                                            ))}
                                        </div>
                                        <div id={`taskDetails${task.id}`} className="collapse mt-2">
                                            {task.description && <p>{task.description}</p>}
                                            {task.reminder && <p><FaBookmark /> {task.reminder}</p>}
                                            {task.duration && <p><FaStopwatch /> {task.duration}</p>}
                                            <button className="btn btn-outline-success btn-sm">Complete Task</button>
                                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => handleShowModal(task)}>
                                                Edit Task
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <AppNavBottom />
        <TaskModal
            show={showModal}
            handleClose={handleCloseModal}
            handleSubmit={handleSubmitModal}
            task={currentTask}
            mode={mode}
        />
    </>
    );
};

export default Tasks;
