import React, { useState } from 'react';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';
import './ChatBot.css';

const Chatbot = () => {
    const [messages, setMessages] = useState([
        { sender: 'bot', text: "Hello! I'm your ADHD coach. How can I assist you today?" }
    ]);
    const [userInput, setUserInput] = useState('');

    const handleSend = () => {
        if (userInput.trim()) {
            // Add user message
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: 'user', text: userInput }
            ]);
            setUserInput('');

            // Simulate bot response
            setTimeout(() => {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { sender: 'bot', text: "I'm here to help! Please tell me more." }
                ]);
            }, 1000);
        }
    };

    const handleInputChange = (e) => setUserInput(e.target.value);

    return (
        <>
        <AppNavTop />
        <div className="container mt-3 margin-bottom-200">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10 col-sm-12">
                    <h1 className="text-center mb-4">ADHD Coach Chatbot</h1>

                    {/* Chat Window */}
                    <div className="chat-window card">
                        <div className="chat-log" id="chat-log">
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`chat-message ${message.sender === 'bot' ? 'bot-message' : 'user-message'}`}
                                >
                                    <span>{message.text}</span>
                                </div>
                            ))}
                        </div>

                        {/* Message Input */}
                        <div className="chat-input d-flex">
                            <input
                                type="text"
                                id="user-input"
                                className="form-control"
                                placeholder="Type your message here..."
                                value={userInput}
                                onChange={handleInputChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                            />
                            <button id="send-btn" className="btn btn-primary ms-2" onClick={handleSend}>
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AppNavBottom />
        </>
    );
};

export default Chatbot;
