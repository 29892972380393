import React, { useState } from 'react';
import './BuddyFeed.css';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';
import ComingSoon from '../ComingSoon/ComingSoon'

const BuddyFeed = () => {
    // Sample data for buddy activities
    const [activities] = useState([
        { id: 1, username: '@johndoe', message: 'Completed 5 tasks today!' },
        { id: 2, username: '@janesmith', message: 'Completed 4 tasks today!' }
    ]);

    const refreshFeed = () => {
        console.log('Buddy feed refreshed!');
        // Logic to fetch and refresh data goes here (replace sample data with API response if needed)
    };

    return (
        <>
        <AppNavTop />
        {/*<section className="buddy-feed container mt-3 margin-bottom-200">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <h2 className="text-center mb-4">Buddy Feed</h2>

                    <div className="text-center mb-4">
                        <button className="btn btn-primary" onClick={refreshFeed}>
                            Refresh Feed
                        </button>
                    </div>

                    <div id="buddyFeedList">
                        {activities.map((activity) => (
                            <div key={activity.id} className="card p-3 mb-3 shadow rounded-border">
                                <h5>{activity.username}</h5>
                                <p className="text-muted">{activity.message}</p>
                                <button className="btn btn-outline-success">
                                    Encourage 👏🎉
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>*/}
        <ComingSoon />
        <AppNavBottom />
        </>
    );
};

export default BuddyFeed;
