// Projects.js

import React, { useState } from 'react';
import './Projects.css';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';
import ProjectModal from './ProjectModal';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';

const projectsData = [
  {
    id: 1,
    title: 'Project Alpha',
    description: 'This is the first project, focusing on initial setup.',
    tasks: [{ id: 1, name: 'Create initial documentation', completed: false }],
    tags: ["Setup", "UI Design"],
  },
  {
    id: 2,
    title: 'Project Beta',
    description: 'This is the first project, focusing on initial setup.',
    tasks: [],
    tags: [],
  },
  // Other projects...
];

const unassignedTaskList = [
        { id: 1, title: "Long example title for this task", description: "Complete the mockup design", dueDate: "2024-10-20", dueTime: "10:00", reminder: "Every Day", project: "Super annoyingly long project title", duration: "45 min", tags: ["test", "next"] },
        { id: 2, title: "Example Task 2", description: "Review project proposal", dueDate: "2024-10-20", dueTime: "11:00", reminder: "Every Week", project: "Project 2"  },
        { id: 3, title: "Example Task 3", description: "Prepare meeting agenda", dueDate: "2024-10-20", dueTime: "13:00", reminder: "Custom", project: "Project 1"  },
        { id: 4, title: "Example Task 4", description: "Do another thing", dueDate: "2024-10-20", dueTime: "8:00", reminder: "Every Day", project: "Project 3"  },
        { id: 5, title: "Example Task 2", description: "Review project proposal", dueDate: "2024-10-20", dueTime: "11:00", reminder: "Every Week", project: "Project 2"  },
        { id: 6, title: "Example Task 3", description: "Prepare meeting agenda", dueDate: "2024-10-20", dueTime: "13:00", reminder: "Custom", project: "Project 1"  },
        { id: 7, title: "Example Task 4", description: "Do another thing", dueDate: "2024-10-20", dueTime: "8:00", reminder: "Every Day", project: "Project 3"  },
        { id: 8, title: "Example Task 4", description: "Do another thing", dueDate: "2024-10-21", dueTime: "7:15", reminder: "Every Day", project: "Project 3"  },
        { id: 9, title: "Example Task 4", description: "Do another thing", dueDate: "2024-10-18", dueTime: "14:00", reminder: "Every Day", project: "Project 3"  },
    ];

const Projects = () => {
  const [projects, setProjects] = useState(projectsData);
  const [unassignedTasks, setUnassignedTasks] = useState(unassignedTaskList);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleAddProject = () => {
    setSelectedProject(null);
    setShowModal(true);
  };

  const handleEditProject = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const handleSaveProject = (project) => {
    setProjects((prevProjects) => {
      if (project.id) {
        return prevProjects.map((p) => (p.id === project.id ? project : p));
      } else {
        return [...prevProjects, { ...project, id: prevProjects.length + 1 }];
      }
    });
  };

  const handleAssignTaskToProject = (projectId, taskId) => {
    const taskToAssign = unassignedTasks.find((task) => task.id === taskId);
    if (!taskToAssign) return;

    setProjects((prevProjects) =>
      prevProjects.map((project) => {
        if (project.id === projectId) {
          return { ...project, tasks: [...project.tasks, { ...taskToAssign }] };
        }
        return project;
      })
    );

    setUnassignedTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));

    // Update selectedProject in the modal if it's currently open and matches projectId
    if (selectedProject && selectedProject.id === projectId) {
      setSelectedProject((prev) => ({
        ...prev,
        tasks: [...prev.tasks, taskToAssign],
      }));
    }
  };

  const handleDeleteProject = (projectId) => {
    setProjects((prevProjects) => prevProjects.filter((project) => project.id !== projectId));
  };

  return (
    <>
      <AppNavTop />
      <h3 className="text-center mt-3">Current Projects</h3>
      <Button variant="primary" className="mb-3" onClick={handleAddProject}>
        <FontAwesomeIcon icon={faPlus} /> Add Project
      </Button>
      <div className="projects-container margin-bottom-200">
        {projects.map((project) => (
          <div key={project.id} className="project-card black-border">
            <h3 className="project-title">{project.title}</h3>
            <div className="project-tags">
              {project.tags.map((tag, index) => (
                <span key={index} className="badge rounded-pill text-bg-secondary">
                  {tag}
                </span>
              ))}
            </div>
            <p className="project-description">{project.description}</p>
            <ul className="task-list">
              {project.tasks.map((task) => (
                <li key={task.id}>{task.name}</li>
              ))}
            </ul>
            <Button variant="primary" onClick={() => handleEditProject(project)}>
              Edit <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          </div>
        ))}
      </div>
      <AppNavBottom />
      <ProjectModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSave={handleSaveProject}
        onDelete={handleDeleteProject}
        onAssignTask={handleAssignTaskToProject}
        project={selectedProject}
        unassignedTasks={unassignedTasks}
      />
    </>
  );
};

export default Projects;

