import React from 'react';
import { Link } from "react-router-dom";

function CallToAction() {
    return (
        <section className="container-fluid bg-light py-5 text-center">
            <h3>Ready to boost your productivity?</h3>
            <p className="lead">Join our community today and start managing your tasks with ADHD-friendly tools.</p>
            <Link className="btn btn-primary btn-lg" to="/register">Try the App Now!</Link>
        </section>
    );
}

export default CallToAction;
