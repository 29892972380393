import React from 'react';

function AboutUs() {
    return (
        <section className="container mt-3">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10 text-center">
                    <h2>About Us</h2>
                    <p className="lead text-muted">Our journey to help people with ADHD manage their tasks and boost productivity.</p>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;
