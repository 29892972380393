import React from 'react';
import { Link } from "react-router-dom";

const ForgotPasswordForm = () => {
    return (
        <section className="container mt-3">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <h2 className="text-center mb-4">Forgot Your Password?</h2>
                    <div className="card p-4 shadow-sm">
                        <p className="text-center mb-4">
                            Enter your email address below, and we will send you instructions to reset your password.
                        </p>
                        <form>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="email" placeholder="Enter your email" />
                            </div>
                            <div className="d-grid gap-2">
                                <button type="submit" className="btn btn-primary">Send Reset Instructions</button>
                            </div>
                            <p className="mt-3 text-center">
                                Remembered your password? <Link to="/login">Login</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ForgotPasswordForm;
