import React, { useState } from 'react';
import './Notifications.css';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';

// A notification is triggered by a reminder.
const notificationsData = [
  { id: 1, title: 'Task due', message: 'Tasks Title is due now', timestamp: '5 minutes ago' },
];

const Notifications = () => {
  const [notifications, setNotifications] = useState(notificationsData);

  const handleDismiss = (id) => {
    setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
  };

  return (
    <>
    <AppNavTop />
    <div className="container-fluid">
      <div className="feed-container mt-3 shadow rounded-border black-border">
        <h2 className="title">Notifications</h2>
        {notifications.length === 0 ? (
          <p className="no-notifications">You're all caught up!</p>
        ) : (
          <ul className="notifications-list">
            {notifications.map((notification) => (
              <li key={notification.id} className="notification-item">
                <div className="notification-content">
                  <h4 className="notification-title">{notification.title}</h4>
                  <p className="notification-message">{notification.message}</p>
                  <span className="notification-timestamp">{notification.timestamp}</span>
                </div>
                <button className="dismiss-button" onClick={() => handleDismiss(notification.id)}>
                  Dismiss
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
    <AppNavBottom />
    </>
  );
};

export default Notifications;
