// ProjectModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const ProjectModal = ({ show, onHide, onSave, onDelete, onAssignTask, project, unassignedTasks }) => {
  const [currentProject, setCurrentProject] = useState({
    id: null,
    title: '',
    description: '',
    tasks: [],
    tags: [],
  });
  const [newTaskId, setNewTaskId] = useState(null);
  const [newTag, setNewTag] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false); // State to manage delete confirmation

  useEffect(() => {
    if (project) {
      setCurrentProject(project);
    } else {
      setCurrentProject({ id: null, title: '', description: '', tasks: [], tags: [] });
    }
  }, [project]);

  const handleChange = (field, value) => {
    setCurrentProject((prev) => ({ ...prev, [field]: value }));
  };

  const handleAddTag = () => {
    if (!newTag.trim()) return;
    setCurrentProject((prev) => ({
      ...prev,
      tags: [...prev.tags, newTag.trim()],
    }));
    setNewTag('');
  };

  const handleRemoveTag = (tagToRemove) => {
    setCurrentProject((prev) => ({
      ...prev,
      tags: prev.tags.filter((tag) => tag !== tagToRemove),
    }));
  };

  const handleAssignTask = () => {
    if (!newTaskId) return;
    const taskToAdd = unassignedTasks.find((task) => task.id === newTaskId);
    onAssignTask(currentProject.id, newTaskId);
    setCurrentProject((prev) => ({
      ...prev,
      tasks: [...prev.tasks, taskToAdd],// Append task to currentProject's tasks array
    }));
    setNewTaskId(null); // Reset task selection
  };

  const handleSave = () => {
    onSave(currentProject);
    onHide();
  };

  const confirmDelete = () => {
    onDelete(currentProject.id);
    setShowDeleteConfirm(false);
    onHide();
  };

  return (
    <>
      {/* Main Project Modal */}
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{project ? 'Edit Project' : 'Add Project'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Title:</Form.Label>
            <Form.Control
              type="text"
              value={currentProject.title}
              onChange={(e) => handleChange('title', e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={currentProject.description}
              onChange={(e) => handleChange('description', e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label>Add Tag:</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                value={newTag}
                placeholder="Enter tag"
                onChange={(e) => setNewTag(e.target.value)}
              />
              <Button variant="secondary" onClick={handleAddTag} className="ml-2">
                <FontAwesomeIcon icon={faPlus} /> Add Tag
              </Button>
            </div>
            <div className="project-tags mt-2">
              {currentProject.tags.map((tag, index) => (
                <span key={index} className="badge rounded-pill text-bg-secondary mr-2">
                  {tag}
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => handleRemoveTag(tag)}
                    className="ml-1 text-danger p-0"
                    style={{ textDecoration: 'none' }}
                  >
                    &times;
                  </Button>
                </span>
              ))}
            </div>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Add Unassigned Task:</Form.Label>
            <div className="d-flex">
              <Form.Control
                as="select"
                value={newTaskId || ''}
                onChange={(e) => setNewTaskId(Number(e.target.value))}
              >
                <option value="">Select an unassigned task</option>
                {unassignedTasks.map((task) => (
                  <option key={task.id} value={task.id}>
                    {task.title}
                  </option>
                ))}
              </Form.Control>
              <Button variant="secondary" onClick={handleAssignTask} className="ml-2">
                <FontAwesomeIcon icon={faPlus} /> Add Task
              </Button>
            </div>
            <div className="assigned-tasks mt-3">
              <h6>Assigned Tasks:</h6>
              <ul>
                {currentProject.tasks.map((task) => (
                  <li key={task.id}>{task.title}</li>
                ))}
              </ul>
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {project && (
            <Button variant="danger" onClick={() => setShowDeleteConfirm(true)}>
              Delete <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
          <Button variant="secondary" onClick={onHide}>Cancel</Button>
          <Button variant="primary" onClick={handleSave}>Save</Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this project? This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirm(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectModal;

