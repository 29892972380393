import React, { useState } from 'react';
import './Mailbox.css'; // Optional for additional custom styles

const Mailbox = () => {
    const [activeTab, setActiveTab] = useState('inbox');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'inbox':
                return (
                    <div className="tab-pane fade show active" id="inbox" role="tabpanel">
                        <div className="row p-4 no-gutters align-items-center">
                            <div className="col-md-6">
                                <h3 className="font-light mb-0"><i className="ti-email mr-2"></i>350 Unread emails</h3>
                            </div>
                            <div className="col-md-6 text-right">
                                <button className="btn btn-success text-white mr-3">
                                    <i className="fa fa-plus"></i> Compose
                                </button>
                                <button className="btn btn-danger text-white">
                                    <i className="fa fa-trash"></i> Delete
                                </button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table email-table table-hover mb-0 font-14">
                                <tbody>
                                    {sampleEmails.map((email) => (
                                        <tr key={email.id}>
                                            <td className="pl-3">
                                                <input type="checkbox" className="custom-control-input" id={`cst${email.id}`} />
                                            </td>
                                            <td><i className={`fa fa-star ${email.starred ? 'text-warning' : ''}`}></i></td>
                                            <td>{email.sender}</td>
                                            <td>
                                                <span className={`badge badge-pill text-white mr-2 badge-${email.tagColor}`}>
                                                    {email.tag}
                                                </span>
                                                <span>{email.message}</span>
                                            </td>
                                            <td><i className="fa fa-paperclip text-muted"></i></td>
                                            <td className="text-muted">{email.date}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            case 'sent':
            case 'spam':
            case 'delete':
                return (
                    <div className="tab-pane fade show active">
                        <div className="row p-3 text-dark">
                            <div className="col-md-6">
                                <h3 className="font-light">This is the {activeTab} tab.</h3>
                                <p>Information about the {activeTab} tab goes here.</p>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body bg-primary text-white mailbox-widget pb-0">
                            <h2 className="text-white pb-3">Your Mailbox</h2>
                            <ul className="nav nav-tabs custom-tab mt-4">
                                {['inbox', 'sent', 'spam', 'delete'].map((tab) => (
                                    <li className="nav-item" key={tab}>
                                        <a
                                            className={`nav-link ${activeTab === tab ? 'active' : ''}`}
                                            onClick={() => setActiveTab(tab)}
                                            role="tab"
                                        >
                                            <span className="d-none d-md-block">{tab.toUpperCase()}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="tab-content p-3" id="myTabContent">
                            {renderTabContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Sample data for emails
const sampleEmails = [
    { id: 1, sender: 'Hritik Roshan', tag: 'Work', tagColor: 'danger', message: 'Lorem ipsum perspiciatis-', date: 'May 13', starred: true },
    { id: 2, sender: 'Genelia Roshan', tag: 'Business', tagColor: 'info', message: 'Inquiry about license for Admin', date: 'May 13', starred: false },
    { id: 3, sender: 'Ritesh Deshmukh', tag: 'Friend', tagColor: 'warning', message: 'Bitbucket (commit Pushed) by Ritesh', date: 'May 13', starred: true },
    { id: 4, sender: 'Akshay Kumar', tag: 'Work', tagColor: 'info', message: 'Perspiciatis unde omnis-', date: 'May 9', starred: false },
];

export default Mailbox;
