import React from 'react';

function Resources() {
    return (
        <section className="container mt-3">
            <div className="row">
                <div className="col-lg-12 text-center mb-4">
                    <h2>Featured ADHD Resources</h2>
                    <p className="text-muted">Browse through our recommended articles, books, podcasts, and apps to support your journey with ADHD.</p>
                </div>

                {/* Articles */}
                <div className="col-lg-6 mb-4">
                    <h4>Articles</h4>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <a href="https://www.additudemag.com/" target="_blank" rel="noopener noreferrer">Understanding ADHD - ADDitude Magazine</a>
                        </li>
                        <li className="list-group-item">
                            <a href="https://chadd.org/about-adhd/overview/" target="_blank" rel="noopener noreferrer">CHADD: Overview of ADHD</a>
                        </li>
                        <li className="list-group-item">
                            <a href="https://www.helpguide.org/articles/add-adhd/adult-adhd-attention-deficit-disorder.htm" target="_blank" rel="noopener noreferrer">Managing Adult ADHD</a>
                        </li>
                    </ul>
                </div>

                {/* Books */}
                <div className="col-lg-6 mb-4">
                    <h4>Books</h4>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <a href="https://www.amazon.com/Driven-Distraction-Revised-Recognizing-Attention/dp/0307743152" target="_blank" rel="noopener noreferrer">Driven to Distraction by Edward M. Hallowell, M.D.</a>
                        </li>
                        <li className="list-group-item">
                            <a href="https://www.amazon.com/You-Mean-Lazy-Stupid-Crazy/dp/0743264487" target="_blank" rel="noopener noreferrer">You Mean I'm Not Lazy, Stupid or Crazy?! by Kate Kelly</a>
                        </li>
                        <li className="list-group-item">
                            <a href="https://www.amazon.com/ADHD-Effect-Marriage-Understand-Connection/dp/1886941971" target="_blank" rel="noopener noreferrer">The ADHD Effect on Marriage by Melissa Orlov</a>
                        </li>
                    </ul>
                </div>

                {/* Podcasts */}
                <div className="col-lg-6 mb-4">
                    <h4>Podcasts</h4>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <a href="https://www.theadhdpodcast.com/" target="_blank" rel="noopener noreferrer">The ADHD Podcast</a>
                        </li>
                        <li className="list-group-item">
                            <a href="https://www.adhdrewired.com/" target="_blank" rel="noopener noreferrer">ADHD ReWired with Eric Tivers</a>
                        </li>
                        <li className="list-group-item">
                            <a href="https://additudemag.libsyn.com/" target="_blank" rel="noopener noreferrer">ADHD Experts Podcast - ADDitude</a>
                        </li>
                    </ul>
                </div>

                {/* Apps */}
                <div className="col-lg-6 mb-4">
                    <h4>Apps</h4>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <a href="https://www.todoist.com/" target="_blank" rel="noopener noreferrer">Todoist - Task Manager App</a>
                        </li>
                        <li className="list-group-item">
                            <a href="https://www.focusmate.com/" target="_blank" rel="noopener noreferrer">Focusmate - Virtual Coworking</a>
                        </li>
                        <li className="list-group-item">
                            <a href="https://www.headspace.com/" target="_blank" rel="noopener noreferrer">Headspace - Meditation App</a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Resources;
