import React, { useState } from 'react';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';

const BuddySearch = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([
        { username: '@johndoe', location: 'New York, USA' },
        { username: '@janesmith', location: 'Los Angeles, USA' },
    ]);

    const handleSearch = () => {
        // Implement the search function here
        console.log('Searching for:', searchQuery);
        // Set search results based on searchQuery
    };

    return (
        <>
        <AppNavTop />
        <section className="container mt-3">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-12">

                    {/* Search Results */}
                    <div id="searchResults">
                        {searchResults.map((result, index) => (
                            <div key={index} className="card p-3 mb-3 shadow rounded-border">
                                <h5>{result.username}</h5>
                                <p className="text-muted">Location: {result.location}</p>
                                <button className="btn btn-outline-success btn-sm">Add Buddy</button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
        <AppNavBottom />
        </>
    );
};

export default BuddySearch;
