import React from 'react';

function TeamMember({ image, name, role, description }) {
    return (
        <div className="col-lg-4 mb-4">
            <div className="card h-100 text-center">
                <div className="card-body">
                    <img src={image} alt="Team Member" className="rounded-circle mb-3" />
                    <h5 className="card-title">{name}</h5>
                    <p className="text-muted">{role}</p>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
}

export default TeamMember;
