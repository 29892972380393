import React, { useState } from 'react';
import './ArchivedTasks.css';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';
import { FaPlus } from 'react-icons/fa';

const ArchivedTasks = () => {
    // Sample data for archived tasks
    const [tasks] = useState([
        { id: 1, title: "Long example title for this task - how long of a title is too long to display? Long example title for this task - how long of a title is too long to display?", description: "Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design ", dueDate: "2024-10-20", dueTime: "10:00 am", reminder: "Every Day", project: "Project 1", duration: "45 min" },
        { id: 2, title: "Example Task 2", description: "Review project proposal", dueDate: "2024-10-22", dueTime: "10:00 am", reminder: "Every Week", project: "Project 2"  },
        { id: 3, title: "Example Task 3", description: "Prepare meeting agenda", dueDate: "2024-10-25", dueTime: "10:00 am", reminder: "Custom", project: "Project 1"  },
    ]);

    const handleRestoreTask = (taskId) => {
        // Logic to restore the task
        console.log(`Restoring task with ID: ${taskId}`);
    };

    return (
        <>
        <AppNavTop />
        <section className="archived-tasks container mt-3 margin-bottom-200">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10 col-sm-12">
                    <h2 className="text-center mb-4">Archived Tasks</h2>
                    <div id="archived-tasks-list">
                        {tasks.map(task => (
                                <div key={task.id} className="task card mb-3 border p-3 black-border shadow rounded-border">
                                    <div className="row">
                                        <h5><span className="badge rounded-pill text-bg-secondary">Misc Tag 1</span></h5>
                                        <h5>{task.title.length > 100 ? `${task.title.substring(0, 100)}...` : task.title}</h5>
                                        {/*<p className="text-muted">Description: {task.description.length > 100 ? `${task.description.substring(0, 100)}...` : task.description}</p>*/}
                                        <a href="#" className="text-decoration-none" data-bs-toggle="collapse" data-bs-target={`#taskDetails${task.id}`}><FaPlus/></a>
                                        <div id={`taskDetails${task.id}`} className="collapse mt-2">
                                            <p><strong>Description:</strong> {task.description}</p>
                                            <p><strong>Due Date: {task.dueDate}</strong></p>
                                            <p><strong>Time: {task.dueTime}</strong></p>
                                            <p><strong>Project: {task.project}</strong></p>
                                            <p><strong>Reminder: {task.reminder}</strong></p>
                                            <p><strong>Duration: {task.duration}</strong></p>
                                            <button className="btn btn-outline-success btn-sm">Complete Task</button>
                                            <button className="btn btn-outline-primary btn-sm">Edit Task</button>
                                            {/*<button type="button" className="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#editTaskModal" onClick={() => handleEditClick(task)}>Edit Task</button>*/}
                                            {/* <button className="btn btn-outline-secondary btn-sm">Clone Task</button> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </section>
        <AppNavBottom />
        </>
    );
};

export default ArchivedTasks;
