import React from 'react';
import './Goals.css';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';

const Goals = () => {
const goals = [
  {
    id: 1,
    title: 'Learn Spanish',
    description: 'Complete an intermediate Spanish course and practice with native speakers.',
    targetDate: '2025-06-01',
    progress: 40,
    category: 'Personal Development'
  },
  {
    id: 2,
    title: 'Run a Half Marathon',
    description: 'Train consistently and build endurance to run a half marathon.',
    targetDate: '2024-09-30',
    progress: 80,
    category: 'Health & Fitness'
  },
  {
    id: 3,
    title: 'Launch a Personal Blog',
    description: 'Create and publish a blog to share personal thoughts and experiences.',
    targetDate: '2024-12-31',
    progress: 25,
    category: 'Hobby'
  },
  {
    id: 4,
    title: 'Save $10,000 for Emergency Fund',
    description: 'Save consistently each month to build a $10,000 emergency fund.',
    targetDate: '2025-03-01',
    progress: 55,
    category: 'Finance'
  },
]

  return (
    <>
    <AppNavTop />
    <div className="user-goals mt-3 margin-bottom-200">
      <h2>Long-Term Goals</h2>
      <div className="goals-grid">
        {goals.map((goal) => (
          <div key={goal.id} className="goal-card">
            <h3 className="goal-title">{goal.title}</h3>
            <p className="goal-description">{goal.description}</p>
            <p className="goal-target">Target Date: {goal.targetDate}</p>
            <div className="progress-container">
              <label className="progress-label">
                Progress: {goal.progress}%
              </label>
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${goal.progress}%` }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    <AppNavBottom />
    </>
  );
};

export default Goals;
