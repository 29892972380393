import React from 'react';
import TeamMember from '../TeamMember/TeamMember'

function OurTeam() {
    return (
        <section className="container mt-3">
            <div className="row">
                <div className="col-lg-12 text-center mb-4">
                    <h3>Meet the Team</h3>
                    <p className="text-muted">A passionate team dedicated to building tools that make a difference.</p>
                </div>

                <TeamMember
                    image="https://via.placeholder.com/150"
                    name="Ben Spak"
                    role="Founder"
                    description="Ben leads the product vision, ensuring that PopVia provides features that help users stay focused and productive."
                />
                <TeamMember
                    image="https://via.placeholder.com/150"
                    name="Emma Kalvelage"
                    role="UX/UI Web Designer"
                    description="Emma has a solid background in UX and is an Informatics Graduate from Indiana University Bloomington"
                />
                <TeamMember
                    image="https://via.placeholder.com/150"
                    name="Tatiana Millsap"
                    role="UX/UI Web Developer"
                    description="Tatiana has a Bachelor's degree in Computer Science, is a skilled UX/UI designer and web developer focused on crafting engaging digital experiences and managing website updates."
                />
                <TeamMember
                    image="https://via.placeholder.com/150"
                    name="Joey Quiroz"
                    role="UX/UI Web Designer"
                    description="Joey is a UX/UI designer from San Diego, focused on creating accessible, user-friendly designs for web and mobile."
                />
                <TeamMember
                    image="https://via.placeholder.com/150"
                    name="Jeff Lindholm"
                    role="Software Engineer"
                    description="Jeff has 38 years of engineering experience, has worked with multiple startups, and loves technology."
                />
                <TeamMember
                    image="https://via.placeholder.com/150"
                    name="Daniel Gutierrez"
                    role="AI Engineer"
                    description=""
                />
                <TeamMember
                    image="https://via.placeholder.com/150"
                    name="Ian Robinson"
                    role="Director of Biz Dev"
                    description=""
                />
            </div>
            <div className="row">
                <div className="col-lg-12 text-center mb-4">
                    <h3>Meet Our Coaches</h3>
                    <p className="text-muted">ADHD coaches who support our members.</p>
                </div>

                <TeamMember
                    image="https://via.placeholder.com/150"
                    name="Peggy Sullivan"
                    role="ADHD Coach"
                    description="Peggy helps people with all flavors of ADHD find the tools that help them thrive."
                />
            </div>
        </section>
    );
}

export default OurTeam;
