import React, { useState } from 'react';
import './BrainDump.css'; // Import the external CSS file
import AppNavTop from '../AppNavTop/AppNavTop'
import AppNavBottom from '../AppNavBottom/AppNavBottom'

const BrainDump = () => {
   const [text, setText] = useState("");
   const [tags, setTags] = useState([]);
   const [newTag, setNewTag] = useState("");

   const handleTextChange = (event) => setText(event.target.value);

   const addTag = () => {
       if (newTag) {
           setTags([...tags, newTag]);
           setNewTag("");
       }
   };

   return (
    <>
    <AppNavTop />
       <div className="brain-dump-container">
           <textarea
               className="brain-dump-textarea"
               placeholder="Type anything that's on your mind..."
               value={text}
               onChange={handleTextChange}
           />
           <div className="brain-dump-tag-input">
               <input
                   type="text"
                   value={newTag}
                   placeholder="Add a tag"
                   onChange={(e) => setNewTag(e.target.value)}
                   className="brain-dump-input"
               />
               <button onClick={addTag} className="brain-dump-button">Add Tag</button>
           </div>
           <div className="brain-dump-tags">
               {tags.map((tag, index) => (
                   <span key={index} className="brain-dump-tag">{tag}</span>
               ))}
           </div>
       </div>
       <AppNavBottom />
    </>
   );
};

export default BrainDump;
