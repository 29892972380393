import React, { useState } from 'react';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
    const [tasks, setTasks] = useState([
        { id: 1, title: "Long example title for this task - how long of a title is too long to display? Long example title for this task - how long of a title is too long to display?", description: "Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design Complete the mockup design ", dueDate: "2024-10-20", dueTime: "10:00 am", reminder: "Every Day", project: "Project 1", duration: "45 min" },
        { id: 2, title: "Example Task 2", description: "Review project proposal", dueDate: "2024-10-22", dueTime: "10:00 am", reminder: "Every Week", project: "Project 2"  },
        { id: 3, title: "Example Task 3", description: "Prepare meeting agenda", dueDate: "2024-10-25", dueTime: "10:00 am", reminder: "Custom", project: "Project 1"  },
    ]);

    const [currentTask, setCurrentTask] = useState(null);

    const handleEditClick = (task) => {
        setCurrentTask(task);
    };

    const handleSubmit = (e)=> {
        e.preventDefault();
        console.log('You clicked submit.');
    }

    return (
        <>
        <AppNavTop />
        <div className="margin-bottom-200">
            {/* Dashboard Section */}
            <section className="container mt-3">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 col-sm-12">
                        <h5 className="text-center">Good Day, User!</h5>
                        <h3 className="text-center">Today's Top Tasks</h3>
                        <div id="task-list" className="mt-4">
                            {tasks.map(task => (
                                <div key={task.id} className="task card mb-3 border black-border p-3 shadow rounded-border">
                                    <div className="row">
                                        <h5 className="text-muted">{task.title.length > 100 ? `${task.title.substring(0, 100)}...` : task.title}</h5>
                                        <div>
                                            <span className="badge rounded-pill bg-danger">Due Today</span>
                                            <span className="badge rounded-pill text-bg-secondary">Misc Tag 1</span>
                                        </div>
                                        {/*<p className="text-muted">Description: {task.description.length > 100 ? `${task.description.substring(0, 100)}...` : task.description}</p>*/}

                                        <a href="#" className="text-decoration-none" data-bs-toggle="collapse" data-bs-target={`#taskDetails${task.id}`}><FaPlus/></a>
                                        <div id={`taskDetails${task.id}`} className="collapse mt-2">
                                            <p><strong>Description:</strong> {task.description}</p>
                                            <p><strong>Due Date: {task.dueDate}</strong></p>
                                            <p><strong>Time: {task.dueTime}</strong></p>
                                            <p><strong>Project: {task.project}</strong></p>
                                            <p><strong>Reminder: {task.reminder}</strong></p>
                                            <p><strong>Duration: {task.duration}</strong></p>
                                            <button className="btn btn-outline-success btn-sm">Complete Task</button>
                                            <button className="btn btn-outline-primary btn-sm">Edit Task</button>
                                            {/*<button type="button" className="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#editTaskModal" onClick={() => handleEditClick(task)}>Edit Task</button>*/}
                                            {/* <button className="btn btn-outline-secondary btn-sm">Clone Task</button> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Link to="/tasks"><button className="btn btn-outline-primary btn-sm">View All Tasks</button></Link>
                    </div>
                </div>
            </section>
        </div>
    <AppNavBottom />
    </>
    );
};

export default Dashboard;
