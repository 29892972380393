import React from 'react';
import './UserInsights.css';
import AppNavTop from '../AppNavTop/AppNavTop'
import AppNavBottom from '../AppNavBottom/AppNavBottom';

const UserInsights = () => {
  const tasks = [
  { id: 1, title: 'Write project proposal', category: 'Work', completed: true, dueDate: '2024-11-05' },
  { id: 2, title: 'Plan team meeting', category: 'Work', completed: false, dueDate: '2024-11-07' },
  { id: 3, title: 'Grocery shopping', category: 'Personal', completed: true, dueDate: '2024-11-03' },
  { id: 4, title: 'Finish book chapter', category: 'Personal', completed: false, dueDate: '2024-11-10' },
  { id: 5, title: 'Update website', category: 'Freelance', completed: false, dueDate: '2024-11-12' }
];
  // Calculate task statistics
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(task => task.completed).length;
  const pendingTasks = totalTasks - completedTasks;
  const completionPercentage = totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;

  return (
    <>
    <AppNavTop />
    <div className="user-insights mt-3 margin-bottom-200">
      <h2>User Insights</h2>
      <div className="insights-grid">
        <div className="insight-card">
          <h3>Total Tasks</h3>
          <p>{totalTasks}</p>
        </div>
        <div className="insight-card">
          <h3>Completed Tasks</h3>
          <p>{completedTasks}</p>
        </div>
        <div className="insight-card">
          <h3>Pending Tasks</h3>
          <p>{pendingTasks}</p>
        </div>
        <div className="insight-card">
          <h3>Completion Rate</h3>
          <p>{completionPercentage}%</p>
        </div>
      </div>
    </div>
    <AppNavBottom />
    </>
  );
};

export default UserInsights;
