import React from 'react';

function OurMission() {
    return (
        <section className="container mt-3">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <h3 className="text-center mb-4">Our Mission</h3>
                    <p className="text-muted text-center">
                        At PopVia, our mission is to empower individuals with ADHD to take control of their time, stay focused, and accomplish their goals.
                        We believe that with the right tools, everyone can maximize their potential, regardless of the challenges ADHD may bring.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default OurMission;
