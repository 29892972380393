import React from 'react';
import './FeaturesSection.css';

function FeaturesSection() {
    const features = [
        { title: 'Task Management', text: 'Easily create, edit, and track tasks with an intuitive, clutter-free interface. Organize tasks by due dates and set reminders.', image: '/images/tasks.png' },
        { title: 'Reminders', text: 'Set custom reminders to help you stay on track. Use our Reminders Overview page to manage all your active reminders in one place.', image: '/images/reminders.png' },
        { title: 'Profile Management', text: 'Create and customize your profile, update personal information, and manage your settings with ease.', image: '/images/user-profile.png' },
    ];
    {/* { title: 'Buddy System', text: 'Connect with accountability buddies. View their progress and encourage them when they complete tasks. Stay motivated together!', image: '/images/buddy-system.png' },
        { title: 'Buddy Feed', text: 'Stay updated on your buddies\' task progress. Check out who completed 3 or more tasks today and send them encouragement.', image: '/images/buddy-feed.png' },
        { title: 'Productivity Insights', text: 'Track your task completion history and productivity streaks. Stay motivated by viewing your progress over time.', image: '/images/insights.png' } */}

    return (
        <section className="container mt-3">
            <div className="row">
                <div className="col-lg-12 text-center mb-4">
                    <h2>Key Features</h2>
                    <p className="text-muted">Explore all the tools we offer to help you stay on track</p>
                </div>

                {/* Feature Cards */}
                {features.map((feature, index) => (
                    <div className="col-lg-4 mb-4" key={index}>
                        <div className="card h-100 text-center">
                            <div className="card-body">
                                <img className="card-image" src={`https://app.popvia.com${feature.image}`} />
                                <h5 className="card-title">{feature.title}</h5>
                                <p className="card-text">{feature.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default FeaturesSection;
