import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaBookmark, FaBolt, FaList, FaArchive, FaSearch, FaFolder, FaClock, FaChartPie, FaStopwatch, FaStopwatch20, FaBrain, FaPen } from 'react-icons/fa';
import './AppNavMain.css';

const AppNavMain = () => {
  return (
    <>
      <button
        className="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvas"
        aria-controls="offcanvas"
      >
        Toolbox
      </button>

      <div
        className="offcanvas offcanvas-start"
        data-bs-scroll="true"
        tabIndex="-1"
        id="offcanvas"
        aria-labelledby="offcanvas"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvas">ADHD Toolbox</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav ms-auto">
              {/*<li className="nav-item">
                  <Link className="nav-link" to="/inbox"><FaEnvelope /> Email Inbox</Link>
              </li>*/}
              {/*<li className="nav-item">
                  <Link className="nav-link" to="/notes"><FaPen /> Notes</Link>
              </li>*/}
              {/*<li className="nav-item">
                  <Link className="nav-link" to="/brain-dump"><FaBrain /> Brain Dump</Link>
              </li>*/}
              {/*<li className="nav-item">
                  <Link className="nav-link" to="/insights"><FaChartPie /> Insights</Link>
              </li>
              {/*<li className="nav-item">
                  <Link className="nav-link" to="/chatbot"><FaBolt /> Chatbot</Link>
              </li>*/}
              {/*<li className="nav-item">
                  <Link className="nav-link" to="/timer"><FaStopwatch20 /> Work Timer</Link>
              </li>*/}
              <li className="nav-item">
                  <Link className="nav-link" to="/projects"><FaFolder /> Projects</Link>
              </li>
              <li className="nav-item">
                  <Link className="nav-link" to="/tasks"><FaList /> Tasks</Link>
              </li>
              {/*<li className="nav-item">
                  <Link className="nav-link" to="/archived-tasks"><FaArchive /> Archived Tasks</Link>
              </li>*/}
              {/*<li className="nav-item">
                  <Link className="nav-link" to="/history"><FaClock /> Browser History</Link>
              </li>*/}
              <li className="nav-item">
                  <Link className="nav-link" to="/feedback"><FaEnvelope /> Give Us Feedback!</Link>
              </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AppNavMain;
