import React, { useState } from 'react';
import './Calendar.css'
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';
import ComingSoon from '../ComingSoon/ComingSoon'

const Calendar = () => {
    const [view, setView] = useState('day');

    const handleViewChange = (newView) => {
        setView(newView);
    };
    return (
        <>
        <AppNavTop />
            {/*<div className="task-calendar mt-3">
                <header className="calendar-header">
                    <h1>Task Calendar</h1>
                    <div className="view-buttons">
                    <button
                        className={`view-button ${view === 'day' ? 'active' : ''}`}
                        onClick={() => handleViewChange('day')}
                    >
                        Day
                    </button>
                    <button
                        className={`view-button ${view === 'week' ? 'active' : ''}`}
                        onClick={() => handleViewChange('week')}
                    >
                        Week
                    </button>
                    <button
                        className={`view-button ${view === 'month' ? 'active' : ''}`}
                        onClick={() => handleViewChange('month')}
                    >
                        Month
                    </button>
                    </div>
                </header>

                <div className="calendar-content">
                    {view === 'day' && (
                    <div className="day-view">
                        <h2>Wednesday 10/29/24</h2>
                        <div className="events">
                        <div className="event"><span>12:00 pm</span><div className="event-box">Event</div></div>
                        <div className="event"><span>1:30 pm</span><div className="event-box">Event</div></div>
                        <div className="event"><span>2:15 pm</span><div className="event-box">Event</div></div>
                        <div className="event"><span>3:00 pm</span><div className="event-box">Event</div></div>
                        <div className="event"><span>3:30 pm</span><div className="event-box">Event</div></div>
                        <div className="event"><span>5:00 pm</span><div className="event-box">Event</div></div>
                        </div>
                    </div>
                    )}

                    {view === 'week' && (
                    <div className="week-view">
                        <h2>Wednesday 10/03/24</h2>
                        <div className="events">
                        <div className="event"><span>12:00 pm</span><div className="event-box">Event</div></div>
                        <div className="event"><span>1:30 pm</span><div className="event-box">Event</div></div>
                        <div className="event"><span>2:15 pm</span><div className="event-box">Event</div></div>
                        </div>
                        <h2>Thursday 10/04/24</h2>
                        <div className="events">
                        <div className="event"><span>12:00 pm</span><div className="event-box">Event</div></div>
                        <div className="event"><span>1:30 pm</span><div className="event-box">Event</div></div>
                        </div>
                    </div>
                    )}

                    {view === 'month' && (
                    <div className="month-view">
                        <h2>October 2024</h2>
                        <div className="calendar-grid">
                        <div className="calendar-day">1</div>
                        <div className="calendar-day">2</div>
                        <div className="calendar-day active">3</div>
                        </div>
                    </div>
                    )}
                </div>
        </div>*/}
        <ComingSoon />
        <AppNavBottom />
        </>
    )

}

export default Calendar;
