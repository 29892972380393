import React, { useState } from 'react';
import './UserSettings.css';
import AppNavTop from '../AppNavTop/AppNavTop';
import AppNavBottom from '../AppNavBottom/AppNavBottom';

const UserSettings = () => {
    const [profilePrivacy, setProfilePrivacy] = useState(false);
    const [buddyNotifications, setBuddyNotifications] = useState(false);
    const [reminderNotifications, setReminderNotifications] = useState(false);

    const handlePushNotifications = () => {
        alert("Push notifications enabled!"); // Example action
    };

    return (
        <>
        <AppNavTop />
        <div className="mt-4 container-fluid">
            <h3 className="text-center">User Settings</h3>
            <div className="settings-container">

                {/* Profile Privacy */}
                <div className="setting-group">
                    <h2>Profile Privacy</h2>
                    <div className="toggle-container">
                        <label htmlFor="profile-privacy">Private Profile</label>
                        <input
                            type="checkbox"
                            id="profile-privacy"
                            checked={profilePrivacy}
                            onChange={() => setProfilePrivacy(!profilePrivacy)}
                        />
                    </div>
                </div>

                {/* Notifications */}
                <div className="setting-group">
                    <h2>Notifications</h2>

                    {/* Buddy Feed Notifications
                    <div className="toggle-container">
                        <label htmlFor="buddy-notifications">Receive Buddy Feed Notifications</label>
                        <input
                            type="checkbox"
                            id="buddy-notifications"
                            checked={buddyNotifications}
                            onChange={() => setBuddyNotifications(!buddyNotifications)}
                        />
                    </div>
                    */}

                    {/* Reminder Notifications */}
                    <div className="toggle-container">
                        <label htmlFor="reminder-notifications">Receive Reminder Notifications</label>
                        <input
                            type="checkbox"
                            id="reminder-notifications"
                            checked={reminderNotifications}
                            onChange={() => setReminderNotifications(!reminderNotifications)}
                        />
                    </div>

                    {/* Allow Push Notifications
                    <button className="push-button" onClick={handlePushNotifications}>
                        Allow Push Notifications
                    </button>*/}
                </div>
            </div>
        </div>
        <AppNavBottom />
        </>
    );
};

export default UserSettings;
