import './App.css';
import HomePage from './pages/website/HomePage/HomePage';
import AboutPage from './pages/website/AboutPage/AboutPage';
import ResourcesPage from './pages/website/ResourcesPage/ResourcesPage';
import RegistrationForm from './components/app/RegistrationForm/RegistrationForm';
import LoginForm from './components/app/LoginForm/LoginForm';
import Dashboard from './components/app/Dashboard/Dashboard'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPasswordForm from './components/app/ForgotPasswordForm/ForgotPasswordForm';
import FeedbackForm from './components/app/FeedbackForm/FeedbackForm';
import ArchivedTasks from './components/app/ArchivedTasks/ArchivedTasks';
import BuddyFeed from './components/app/BuddyFeed/BuddyFeed';
import PublicProfile from './components/app/PublicProfile/PublicProfile';
import UserSettings from './components/app/UserSettings/UserSettings';
import SalesPage from './pages/website/SalesPage/SalesPage';
import EditProfile from './components/app/EditProfile/EditProfile';
import CommunityPage from './pages/website/CommunityPage/CommunityPage';
import ChatBot from './components/app/ChatBot/ChatBot';
import BuddySearch from './components/app/BuddySearch/BuddySearch';
import Tasks from './components/app/Tasks/Tasks';
import Calendar from './components/app/Calendar/Calendar'
import Notifications from './components/app/Notifications/Notifications';
import Projects from './components/app/Projects/Projects';
import BrowserHistory from './components/app/BrowserHistory/BrowserHistory';
import UserInsights from './components/app/UserInsights/UserInsights';
import Goals from './components/app/Goals/Goals';
import WorkTimer from './components/app/WorkTimer/WorkTimer';
import BrainDump from './components/app/BrainDump/BrainDump';
import Notes from './components/app/Notes/Notes';
import Inbox from './components/app/Inbox/Inbox';
import Coaches from './components/app/Coaches/Coaches'
import CoachProfile from './components/app/Coaches/CoachProfile/CoachProfile';

import Hotjar from '@hotjar/browser';

const siteId = 5058771;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

function App() {
  return (
    <BrowserRouter>
      <Routes>
          {/* Website Components */}
          <Route exact path="/" element={<HomePage />}></Route>
          <Route path="/about" element={<AboutPage />}></Route>
          <Route path="/resources" element={<ResourcesPage />}></Route>
          <Route path="/lifetime" element={<SalesPage />}></Route>
          <Route path="/discord" element={<CommunityPage />}></Route>

          {/* App Components */}
          <Route path="/notifications" element={<Notifications />}></Route>
          <Route path="/register" element={<RegistrationForm />}></Route>
          <Route path="/login" element={<LoginForm />}></Route>
          <Route path="/forgot-password" element={<ForgotPasswordForm />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/feedback" element={<FeedbackForm />}></Route>
          <Route path="/archived-tasks" element={<ArchivedTasks />}></Route>
          <Route path="/feed" element={<BuddyFeed />}></Route>
          <Route path="/profile" element={<PublicProfile />}></Route>
          <Route path="/settings" element={<UserSettings />}></Route>
          <Route path="/edit-profile" element={<EditProfile />}></Route>
          <Route path="/chatbot" element={<ChatBot />}></Route>
          <Route path="/buddy-search" element={<BuddySearch />}></Route>
          <Route path="/tasks" element={<Tasks />}></Route>
          <Route path="/calendar" element={<Calendar />}></Route>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/history" element={<BrowserHistory />}></Route>
          <Route path="/insights" element={<UserInsights />}></Route>
          <Route path="/goals" element={<Goals />}></Route>
          <Route path="/timer" element={<WorkTimer />}></Route>
          <Route path="/brain-dump" element={<BrainDump />}></Route>
          <Route path="/notes" element={<Notes />}></Route>
          <Route path="/inbox" element={<Inbox />}></Route>
          <Route path="/coaches" element={<Coaches />}></Route>
          <Route path="/coach-profile" element={<CoachProfile />}></Route>
      </Routes>
      </BrowserRouter>
  );
}

export default App;
