import React from 'react';
import './HomePage.css';
import NavBar from '../../../components/website/global/NavBar/NavBar'
import HeroSection from '../../../components/website/HeroSection/HeroSection'
import FeaturesSection from '../../../components/website/FeaturesSection/FeaturesSection'
import CallToAction from '../../../components/website/CallToAction/CallToAction'
import Footer from '../../../components/website/global/Footer/Footer'

function HomePage() {
    return (
        <>
            <NavBar />

            <HeroSection />

            <FeaturesSection />

            <CallToAction />

            <Footer />
        </>
    );
}

export default HomePage;
