import React from 'react';
import { Outlet, Link } from "react-router-dom";

function HeroSection() {
    return (
        <>
            <section className="container-fluid bg-primary text-white text-center py-5">
                <h1 className="text-white">Your Productivity Toolbox</h1>
                <p className="lead">Helping you stay organized, focused, and productive with an ADHD-friendly approach</p>
                <Link className="btn btn-light btn-lg" to="/register">Demo the App Now!</Link>
            </section>
            <Outlet />
        </>
    );
}

export default HeroSection;
